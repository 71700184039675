import React, { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import TournamentWidget from 'src/views/widgets/tournamentWidget/tournamentWidget';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

const Calendar = Loadable(lazy(() => import('../views/apps/calendar/BigCalendar')));
const Faq = Loadable(lazy(() => import('../views/pages/faq/Faq')));
const AccountSetting = Loadable(
  lazy(() => import('../views/pages/account-setting/AccountSetting')),
);

const Coaches = Loadable(lazy(() => import('../views/pages/coaches/Coaches')));
const CoachesPackages = Loadable(
  lazy(() => import('../views/pages/coachesPackages/CoachesPackages')),
);
const Schedule = Loadable(lazy(() => import('../views/pages/schedule/Schedule')));
const LoyaltyProgram = Loadable(
  lazy(() => import('../views/pages/loyalty-program/LoyaltyProgram')),
);
const Reporting = Loadable(lazy(() => import('../views/pages/reporting/Reporting')));
const Coupons = Loadable(lazy(() => import('../views/pages/coupons/Coupons')));
const Discounts = Loadable(lazy(() => import('../views/pages/discounts/Discounts')));
const MalaebLive = Loadable(lazy(() => import('../views/pages/malaeb-live/MalaebLiveBookings')));
const ViewVideo = Loadable(lazy(() => import('../views/pages/malaeb-live/ViewVideo')));
const ManageStadium = Loadable(lazy(() => import('../views/pages/manage-stadium/ManageStadium')));
const Gems = Loadable(lazy(() => import('../views/pages/gems/Gems')));
const GemBreakdown = Loadable(lazy(() => import('../views/pages/gems/components/GemBreakdown')));
const Invoices = Loadable(lazy(() => import('../views/pages/billing/Billing')));
const OnlinePayments = Loadable(
  lazy(() => import('../views/pages/billing/components/OnlinePayments')),
);
const Tournaments = Loadable(lazy(() => import('../views/pages/tournaments/Tournaments')));
const Wallet = Loadable(lazy(() => import('../views/pages/wallet/Wallet')));
const SuccessPage = Loadable(lazy(() => import('../views/pages/success-page/SuccessPage')));
const ErrorPage = Loadable(lazy(() => import('../views/pages/error-page/ErrorPage')));
const WaitingList = Loadable(
  lazy(() => import('../views/pages/schedule/components/WaitingList/WaitingList')),
);
const FixedBookings = Loadable(lazy(() => import('../views/pages/fixed-bookings/FixedBookings')));

// widget
const WidgetCards = Loadable(lazy(() => import('../views/widgets/cards/WidgetCards')));
const WidgetBanners = Loadable(lazy(() => import('../views/widgets/banners/WidgetBanners')));
const WidgetCharts = Loadable(lazy(() => import('../views/widgets/charts/WidgetCharts')));

// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ResetPassword = Loadable(
  lazy(() => import('../views/authentication/reset-password/ResetPassword')),
);

const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/auth2/ForgotPassword2')),
);
const TwoSteps = Loadable(lazy(() => import('../views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('../views/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

// landingpage
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));
const TournamentOrganizers = Loadable(
  lazy(() => import('../views/pages/tournamentOrganizers/TournamentOrganizers')),
);
const TournamentTeams = Loadable(
  lazy(() => import('../views/pages/tournamentTeams/TournamentTeams')),
);
const TournamentProfile = Loadable(
  lazy(() => import('../views/pages/tournaments/components/TournamentInfo/TournamentProfile')),
);

const AuthWrapper = (children: React.ReactNode) => {
  const location = useLocation();

  const isResetPasswordPage = location.pathname.startsWith('/auth/reset_password');

  const path = isResetPasswordPage ? location.pathname : null;

  // const { hasUsers } = useAuth(); // Assume this hook provides user information
  const user = useSelector((state: AppState) => state.authReducer?.user);

  if (!user) {
    return <Navigate to={isResetPasswordPage ? `${path}` : '/auth/login'} replace />;
  }

  return <FullLayout>{children}</FullLayout>;
};

const Router = [
  {
    path: '/auth/reset_password/:token/:email',
    element: <ResetPassword />,
  },
  {
    path: '/',
    element: <AuthWrapper />,
    children: [
      { path: '/', element: <Navigate to="/pages/schedule" /> },
      { path: '/pages/schedule/fixed-bookings', element: <FixedBookings /> },
      { path: '/pages/waiting-list', element: <WaitingList /> },
      { path: '/apps/calendar', element: <Calendar /> },
      { path: '/pages/faq', element: <Faq /> },
      { path: '/pages/account-settings', element: <AccountSetting /> },
      { path: '/widgets/cards', element: <WidgetCards /> },
      { path: '/widgets/banners', element: <WidgetBanners /> },
      { path: '/widgets/charts', element: <WidgetCharts /> },
      { path: '/pages/coaches', element: <Coaches /> },
      { path: '/pages/discounts', element: <Discounts /> },
      { path: '/pages/coupons', element: <Coupons /> },
      { path: '/pages/:stadiumId/coaches/:id/packages', element: <CoachesPackages /> },
      {
        path: '/pages/:stadiumId/tournaments/:status/:id/organizers',
        element: <TournamentOrganizers />,
      },
      { path: '/pages/:stadiumId/tournaments/:status/:id/teams', element: <TournamentTeams /> },
      { path: '/pages/:stadiumId/tournaments/:status/:id', element: <TournamentProfile /> },
      { path: '/pages/schedule', element: <Schedule /> },
      { path: '/pages/loyalty-program', element: <LoyaltyProgram /> },
      { path: '/pages/reporting', element: <Reporting /> },
      { path: '/pages/malaeb-live/malaeb-live-bookings', element: <MalaebLive /> },
      { path: '/pages/malaeb-live/videos', element: <ViewVideo /> },
      { path: '/pages/malaeb-live/video/:bookingId', element: <ViewVideo /> },
      { path: '/pages/manage-stadium', element: <ManageStadium /> },
      { path: '/pages/billing/invoices', element: <Invoices /> },
      { path: '/pages/billing/online-payments', element: <OnlinePayments /> },
      { path: '/pages/gems/manage-gems', element: <Gems /> },
      { path: '/pages/gems/breakdown', element: <GemBreakdown /> },
      { path: '/pages/tournaments/:status', element: <Tournaments /> },
      { path: '/pages/wallet', element: <Wallet /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/register2', element: <Register2 /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/two-steps', element: <TwoSteps /> },
      { path: '/auth/two-steps2', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '/charge-wallet/failure/:id', element: <ErrorPage /> },
      { path: '/charge-wallet/success/:id', element: <SuccessPage /> },
      { path: '/landingpage', element: <Landingpage /> },
      { path: '*', element: <Navigate to="/auth/404" /> },

      {
        path: '/tournaments/widget/:stadiumId/:id/:token',
        element: <TournamentWidget />,
      },
    ],
  },
];

export default Router;
