import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<TournamentOrganizerData, { page: number; search: string }>({
    query: (body) => ({
      url: `v4/pitch-owner-app/users/search?page=${body.page}`,
      method: 'POST',
      body: {
        search: body.search,
      },
    }),
  });

export interface TournamentOrganizerData {
  data: Organizer[];
  links: {
    first: string;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
}

export interface Organizer {
  id: number;
  name: string;
  phone_number: number;
}
