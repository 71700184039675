import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Tournament } from './getTournaments';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Tournament, AddTournamentBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/edit`,
      method: 'PUT',
      body,
    }),
  });

export interface AddTournamentBody {
  id: number;
  name: string;
  name_ar: string;
  venue: string;
  stadium_id: number;
  tournament_type_id: TournamentsTypes;
  sport_type_id: number;
  categories: {
    tournament_category_id: number;
    max_number_of_teams: number;
  }[];
  start_date: string;
  end_date: string;
  registration_deadline: string;
  price_per_team: number | string;
  teams_per_group: number | string;
  teams_to_qualify_per_group: number | string;
  is_live: 0 | 1;
  is_global: 0 | 1;
  is_private: 0 | 1;
  gender: string;
  prizes: string;
  daily_timings: string;
  additional_info: string;
  supported_payment_methods: PaymentMethods[];
}

export enum TournamentsTypes {
  ROUND_ROBIN = 1,
  DOUBLE_ROUND_ROBIN = 2,
  ROUND_ROBIN_SINGLE_ELIMINATION = 3,
  SINGLE_ELIMINATION = 4,
}

export enum PaymentMethods {
  Cash = '1',
  Tap = '5',
  ApplePay = '11',
}
