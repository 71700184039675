import { Box, Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { TabsProps, TournamentWaitingListStatus } from './ApprovalQueue';
import useToast from 'src/hooks/useToast';
import { useAcceptOrRejectWaitingListRequestMutation } from 'src/services/tournaments';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import useLang from 'src/hooks/useLang';

const RejectedList = (props: TabsProps) => {
  const { toastPromise } = useToast();
  const { getTranslatedTitle } = useLang();

  const [acceptOrRejectWaitingListRequest] = useAcceptOrRejectWaitingListRequestMutation();

  const selectedTournament = useSelector(
    (state: AppState) => state.tournamentReducer.selectedTournament,
  );

  const handleAccept = (teamId: number) => {
    toastPromise({
      request: () => {
        return acceptOrRejectWaitingListRequest({
          id: selectedTournament?.id,
          teamId,
          body: { accept: 1, rejection_reason: '' },
        });
      },
      onSuccess: (result: any) => {
        if ('error' in result) {
          throw result.error;
        }

        props.setValue(TournamentWaitingListStatus.PendingPayment.toString());

        return t('ApprovalQueue.teamApprovedSuccessfully');
      },
      onError: (err) => {
        if (err.data && err.data.errors) {
          const errorMessages = Object.values(err.data.errors).flat().join('. ');

          return errorMessages;
        }

        if (err?.data?.message) {
          return err?.data?.message;
        }

        return t('TournamentTeams.errorOccurred');
      },
    });
  };

  if (props.isLoading) {
    return (
      <Box>
        {Array.from({ length: 4 }).map((_, cellIndex) => (
          <Skeleton key={cellIndex} variant="rectangular" sx={{ mb: 3, height: 50 }} />
        ))}
      </Box>
    );
  }

  const noData = props.data?.data?.length === 0 && !props.isLoading;

  return (
    <Box>
      {props.data?.data?.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          gap={2}
          alignItems="center"
          mb={3}
          justifyContent="space-between"
        >
          <Typography variant="h6" width={200}>
            {item.team_players?.map((player) => player.name)?.join(' & ')}
          </Typography>

          <Typography variant="h6">{getTranslatedTitle(item.category, 'name')}</Typography>

          <Stack direction="row" gap={2} alignItems="center">
            <Button
              color="success"
              variant="contained"
              size="large"
              fullWidth
              onClick={() => handleAccept(item.id)}
              sx={{ width: 150 }}
            >
              {t('ApprovalQueue.accept')}
            </Button>
          </Stack>
        </Stack>
      ))}
      {!noData && <Divider />}
      {noData && (
        <Typography textAlign={'center'} mt={20} variant="h6">
          {t('ApprovalQueue.noRejectedTeams')}
        </Typography>
      )}
    </Box>
  );
};

export default RejectedList;
