import moment, { Moment } from 'moment';
import 'moment/locale/ar';

import { useEffect } from 'react';
import { useSelector } from 'src/store/Store';

const useMoment = () => {

    const customizer = useSelector(state => state.customizer)

    useEffect(() => {
        if (customizer.activeDir === 'rtl') {
            // Set the locale to Arabic
            moment.locale('ar');

            return
        }

        // Set the locale to English
        moment.locale('en');

    }, [customizer.activeDir])

    return [moment] as unknown as [Moment | any]

}

export default useMoment