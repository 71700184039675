import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { TabsProps, TournamentWaitingListStatus } from './ApprovalQueue';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import useToast from 'src/hooks/useToast';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { useAcceptOrRejectWaitingListRequestMutation } from 'src/services/tournaments';
import useLang from 'src/hooks/useLang';

const PendingPayments = (props: TabsProps) => {
  const [open, setOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState(0);

  const { toastPromise } = useToast();
  const { getTranslatedTitle } = useLang();

  const selectedTournament = useSelector(
    (state: AppState) => state.tournamentReducer.selectedTournament,
  );

  const [acceptOrRejectWaitingListRequest] = useAcceptOrRejectWaitingListRequestMutation();

  const handleAcceptOrReject = (accept: 1 | 0, teamId: number, rejection_reason: string) => {
    toastPromise({
      request: () => {
        return acceptOrRejectWaitingListRequest({
          id: selectedTournament?.id,
          teamId,
          body: { accept, rejection_reason },
        });
      },
      onSuccess: (result: any) => {
        if ('error' in result) {
          throw result.error;
        }

        if (accept === 0) {
          props.setValue(TournamentWaitingListStatus.Rejected.toString());
        }

        if (accept === 1) {
          props.setValue(TournamentWaitingListStatus.Pending.toString());
        }

        setSelectedTeamId(0);

        return accept === 1
          ? t('ApprovalQueue.teamApprovedSuccessfully')
          : t('ApprovalQueue.teamRejectedSuccessfully');
      },
      onError: (err) => {
        setSelectedTeamId(0);

        if (err.data && err.data.errors) {
          const errorMessages = Object.values(err.data.errors).flat().join('. ');

          return errorMessages;
        }

        if (err?.data?.message) {
          return err?.data?.message;
        }

        return t('TournamentTeams.errorOccurred');
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setRejectionReason('');
    setSelectedTeamId(0);
  };

  const handleSubmit = () => {
    setOpen(false);
    setRejectionReason('');
    handleAcceptOrReject(0, selectedTeamId, rejectionReason);
  };

  const handleRejectClick = (teamId: number) => {
    setOpen(true);
    setSelectedTeamId(teamId);
  };

  if (props.isLoading) {
    return (
      <Box>
        {Array.from({ length: 4 }).map((_, cellIndex) => (
          <Skeleton key={cellIndex} variant="rectangular" sx={{ mb: 3, height: 50 }} />
        ))}
      </Box>
    );
  }

  const noData = props.data?.data?.length === 0 && !props.isLoading;

  return (
    <Box>
      {props.data?.data?.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          gap={2}
          alignItems="ce nter"
          mb={3}
          justifyContent="space-between"
        >
          <Typography variant="h6" width={200}>
            {item.team_players?.map((player) => player.name)?.join(' & ')}
          </Typography>

          <Typography variant="h6">{getTranslatedTitle(item.category, 'name')}</Typography>

          <Stack direction="row" gap={2} alignItems="center">
            <Button
              color="error"
              variant="contained"
              size="large"
              fullWidth
              onClick={() => handleRejectClick(item.id)}
              sx={{ width: 150 }}
            >
              {t('ApprovalQueue.reject')}
            </Button>
          </Stack>
        </Stack>
      ))}

      {!noData && <Divider />}

      {noData && (
        <Typography textAlign={'center'} mt={20} variant="h6">
          {t('ApprovalQueue.noPendingPayments')}
        </Typography>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle variant="subtitle2">{t('ApprovalQueue.teamRejection')}</DialogTitle>
        <Box>
          <DialogContent>
            <CustomTextField
              label={t('ApprovalQueue.reason')}
              fullWidth
              rows={4}
              multiline
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            {t('ApprovalQueue.cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('ApprovalQueue.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PendingPayments;
