import { Box, Tab, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import BlankCard from 'src/components/shared/BlankCard';
import { Drawer, DrawerHeader } from 'src/components/shared';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Requests from './Requests';
import { AccountBox, Cancel, RequestQuote } from '@mui/icons-material';
import PendingPayments from './PendingPayments';
import RejectedList from './RejectedList';
import { useLazyGetTournamentWaitingListQuery } from 'src/services/tournaments';
import { AppState } from 'src/store/Store';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { TournamentWaitingListResponse } from 'src/services/tournaments/getTournamentWaitingList';

//1=>Approved, 2=>Pending, 3=>Pending Payment, 4=>Canceled, 5=> Failed Payment, 6=>Rejected
export enum TournamentWaitingListStatus {
  Approved = 1,
  Pending = 2,
  PendingPayment = 3,
  Canceled = 4,
  FailedPayment = 5,
  Rejected = 6,
}

interface IProps {
  open: boolean;
  onClose: () => void;
  loadTournamentDetails: () => void;
}

export type TabsProps = {
  data: TournamentWaitingListResponse;
  isLoading: boolean;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const ApprovalQueue = (props: IProps) => {
  const selectedTournament = useSelector(
    (state: AppState) => state.tournamentReducer.selectedTournament,
  );

  const [value, setValue] = React.useState(TournamentWaitingListStatus.Pending.toString());

  const [getWaitingList, { data, isLoading, isFetching }] = useLazyGetTournamentWaitingListQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onCloseDrawer = () => {
    setValue(TournamentWaitingListStatus.Pending.toString());
    props.loadTournamentDetails();
    props.onClose();
  };

  const loadData = () => {
    getWaitingList({
      id: selectedTournament?.id,
      status: Number(value),
    });
  };

  useEffect(() => {
    if (selectedTournament?.id && props.open) {
      loadData();
    }
  }, [value, props.open]);

  const tabsProps = useMemo(
    () => ({
      data,
      isLoading: isLoading || isFetching,
      setValue,
    }),
    [data, isLoading, isFetching, props.open],
  );

  const tabConfig = [
    {
      value: TournamentWaitingListStatus.Pending.toString(),
      label: t('ApprovalQueue.requests'),
      icon: <AccountBox />,
      component: () => <Requests {...tabsProps} />,
    },
    {
      value: TournamentWaitingListStatus.PendingPayment.toString(),
      label: t('ApprovalQueue.pendingPayments'),
      icon: <RequestQuote />,
      component: () => <PendingPayments {...tabsProps} />,
    },
    {
      value: TournamentWaitingListStatus.Rejected.toString(),
      label: t('ApprovalQueue.rejected'),
      icon: <Cancel />,
      component: () => <RejectedList {...tabsProps} />,
    },
  ];

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '50%',
          padding: '20px',
        },
      }}
      className="booking-form-drawer"
      anchor="right"
      open={props.open}
      onClose={onCloseDrawer}
    >
      <BlankCard className="booking-form-card">
        <DrawerHeader title={t('ApprovalQueue.title')} onClose={onCloseDrawer} />

        <Box
          sx={{ height: 'calc(100vh - 100px)', overflow: 'auto', paddingBottom: '20px' }}
          className="approval-queue-content"
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="approval-queue-tabs">
              <TabList onChange={handleChange} aria-label="approval queue tabs" variant="fullWidth">
                {tabConfig.map(({ value, label, icon }) => (
                  <Tab key={value} label={label} value={value} icon={icon} iconPosition="top" />
                ))}
              </TabList>
            </Box>
            {tabConfig.map(({ value, component: Component }) => (
              <TabPanel key={value} value={value} className="approval-queue-tab-panel">
                <Component />
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </BlankCard>
    </Drawer>
  );
};

export default ApprovalQueue;
