import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import { FormikProps } from 'formik';
import CustomCheckbox from 'src/components/forms/theme-elements/CustomCheckbox';
import { t } from 'i18next';
import { useSearchUsersMutation } from 'src/services/tournaments';
import { Organizer } from 'src/services/tournaments/searchUsers';

export default function SearchUserInput({
  formik,
}: {
  formik: FormikProps<{ users_ids: number[]; users: Organizer[] }>;
}) {
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [users, setUsers] = React.useState<Organizer[]>([]);

  const [searchUsers, { isLoading }] = useSearchUsersMutation();

  React.useEffect(() => {
    if (search && search.trim().length > 2) {
      searchUsers({ search, page }).then((res) => {
        if ('data' in res) {
          if (res.data) {
            setUsers([...users, ...res.data.data]);
          }
        }
      });
    }
  }, [search, page]);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      value={formik.values.users}
      options={search?.trim().length > 2 ? users || [] : []}
      disableCloseOnSelect
      filterOptions={(x) => x}
      includeInputInList
      noOptionsText={
        search?.trim().length > 2
          ? t('CouponsScreen.noUsers')
          : t('CouponsScreen.enterAtLeast3Chars')
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading && page === 1}
      onChange={(_, newValue) => {
        formik.setFieldValue('users', newValue);
        formik.setFieldValue(
          'users_ids',
          newValue.map((user) => user.id),
        );
        setTimeout(() => {
          formik.validateField('users');
        }, 100);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <CustomCheckbox style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      fullWidth
      renderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder={t('TournamentOrganizers.searchWithName')}
          error={formik.touched.users && Boolean(formik.errors.users)}
          helperText={formik.touched.users && formik.errors.users}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onBlur={() => {
            formik.validateField('users');
            setPage(1);
            setUsers([]);
            setSearch('');
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            setPage(page + 1);
          }
        },
      }}
    />
  );
}
