import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, Body>({
    query: (body) => ({
      url: `v4/tournaments/${body.tournamentId}/download-teams-report`,
      method: 'POST',
      body,
    }),
  });

interface Body {
  tournamentId: number;
  category: number | string;
}
