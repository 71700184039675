import { Box } from '@mui/system';
import React from 'react';
import TournamentProfile from 'src/views/pages/tournaments/components/TournamentInfo/TournamentProfile';

const TournamentWidget = () => {
  return (
    <Box p={5}>
      <TournamentProfile />
    </Box>
  );
};

export default TournamentWidget;
