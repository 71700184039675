import { LinearProgressProps, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; total: number; registered: number },
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 50 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${props.registered} / ${props.total}`}
        </Typography>
      </Box>
    </Box>
  );
}
