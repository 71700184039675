import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const useValidationSchema = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    players: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('TournamentTeams.playerNameRequired')),
        phone: Yup.string().required(t('TournamentTeams.playerPhoneRequired')),
      }),
    ),
  });

  return { validationSchema: schema };
};

export default useValidationSchema;
