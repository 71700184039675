import React, { useEffect, useMemo } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEditMatchMutation } from 'src/services/tournaments';
import useToast from 'src/hooks/useToast';
import CustomFormLabel from 'src/components/forms/theme-elements/CustomFormLabel';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import { useSelector } from 'src/store/Store';
import BlankCard from 'src/components/shared/BlankCard';
import { Drawer, DrawerHeader } from 'src/components/shared';
import toast from 'react-hot-toast';
import CustomRadio from 'src/components/forms/theme-elements/CustomRadio';
import { ScheduleMatch } from 'src/services/tournaments/getTournamentSchedule';
import ScoreInput from './ScoreInput';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { EditMatchBody } from 'src/services/tournaments/editMatch';
import useMoment from 'src/views/pages/schedule/hooks/useMoment';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedMatch?: ScheduleMatch;
  loadMatches: () => void;
}

const EditMatchForm = ({ open, onClose, selectedMatch, loadMatches }: Props) => {
  const tournamentDetails = useSelector((state) => state.tournamentReducer.selectedTournament);

  const [updateMatch] = useEditMatchMutation();

  const { toastPromise } = useToast();
  const [moment] = useMoment();
  const theme = useTheme();

  const formik = useFormik<EditMatchBody>({
    initialValues: {
      match_date: '',
      match_time: '',
      pitch_name: '',
      stream_url: '',
      is_live: 0,
      set_end_score: '',
      teams_results: [
        { team_1: '', team_2: '', winner_team: '' },
        { team_1: '', team_2: '', winner_team: '' },
        { team_1: '', team_2: '', winner_team: '' },
      ],
    },
    onSubmit: (values) => {
      if (!selectedMatch) {
        toast.error(t('EditMatch.matchUpdateFailed'));

        return;
      }

      toastPromise({
        request: () =>
          updateMatch({
            tournamentId: tournamentDetails?.id,
            matchId: selectedMatch.id,
            body: {
              ...values,
              is_live: Number(values.is_live) as 0 | 1,
              match_time: values?.match_time
                ? moment(values.match_time, 'HH:mm').format('HH:mm')
                : '',
            },
          }),
        onSuccess: (result) => {
          if ('error' in result) {
            throw result.error;
          }
          loadMatches();
          onClose();
          formik.resetForm();

          return t('EditMatch.matchUpdatedSuccessfully');
        },
        onError: (err) => {
          if (err.data && err.data.errors) {
            const errorMessages = Object.values(err.data.errors).flat().join('. ');

            return errorMessages;
          }
          if (err?.data?.message) {
            return err?.data?.message;
          }

          return t('EditMatch.matchUpdateFailed');
        },
      });
    },
  });

  useEffect(() => {
    if (selectedMatch) {
      formik.setValues({
        match_date: selectedMatch?.match_date,
        match_time: selectedMatch?.match_time,
        pitch_name: selectedMatch?.pitch_name,
        stream_url: selectedMatch?.stream_url,
        is_live: selectedMatch?.is_live ? 1 : 0,
        set_end_score: '',
        teams_results: selectedMatch?.teams_results?.map((result) => ({
          ...result,
          team_1: result?.team_1?.toString() || '',
          team_2: result?.team_2?.toString() || '',
        })),
      });
    }
  }, [selectedMatch]);

  const combinedScoring = useMemo(() => {
    const results = formik.values.teams_results;
    const set1 = results?.[0] || { team_1: '', team_2: '' };
    const set2 = results?.[1] || { team_1: '', team_2: '' };
    const set3 = results?.[2] || { team_1: '', team_2: '' };

    return [set1, set2, set3];
  }, [formik.values.teams_results, selectedMatch]);

  const onCloseDrawer = () => {
    onClose();
    formik.resetForm();
  };

  console.log(selectedMatch);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '50%',
          padding: '20px',
        },
      }}
      className="booking-form-drawer"
      anchor="right"
      open={open}
      onClose={onCloseDrawer}
    >
      <BlankCard className="booking-form-card">
        <DrawerHeader title={t('EditMatch.editMatch')} onClose={onCloseDrawer} />
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              height: 'calc(100vh - 100px)',
              overflow: 'auto',
              paddingBottom: '40px',
              paddingRight: 5,
              paddingLeft: 5,
            }}
            className="booking-form-content"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomFormLabel htmlFor="match_date">{t('EditMatch.matchDate')}</CustomFormLabel>
                <CustomTextField
                  id="match_date"
                  type="date"
                  fullWidth
                  {...formik.getFieldProps('match_date')}
                  error={formik.touched.match_date && Boolean(formik.errors.match_date)}
                  helperText={formik.touched.match_date && formik.errors.match_date}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomFormLabel htmlFor="match_time">{t('EditMatch.matchTime')}</CustomFormLabel>
                <CustomTextField
                  id="match_time"
                  type="time"
                  fullWidth
                  {...formik.getFieldProps('match_time')}
                  error={formik.touched.match_time && Boolean(formik.errors.match_time)}
                  helperText={formik.touched.match_time && formik.errors.match_time}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomFormLabel htmlFor="pitch_name">{t('EditMatch.pitchName')}</CustomFormLabel>
                <CustomTextField
                  id="pitch_name"
                  fullWidth
                  {...formik.getFieldProps('pitch_name')}
                  error={formik.touched.pitch_name && Boolean(formik.errors.pitch_name)}
                  helperText={formik.touched.pitch_name && formik.errors.pitch_name}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomFormLabel htmlFor="stream_url">{t('EditMatch.streamUrl')}</CustomFormLabel>
                <CustomTextField
                  id="stream_url"
                  fullWidth
                  {...formik.getFieldProps('stream_url')}
                  error={formik.touched.stream_url && Boolean(formik.errors.stream_url)}
                  helperText={formik.touched.stream_url && formik.errors.stream_url}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomFormLabel htmlFor="is_live">{t('EditMatch.isLive')}</CustomFormLabel>
                <FormControl>
                  <RadioGroup
                    row
                    value={formik.values.is_live}
                    onChange={(e) => formik.setFieldValue('is_live', Number(e.target.value))}
                  >
                    <FormControlLabel
                      value={1}
                      control={<CustomRadio />}
                      label={t('Common.yes')}
                      name="is_live"
                    />
                    <FormControlLabel
                      value={0}
                      control={<CustomRadio />}
                      label={t('Common.no')}
                      name="is_live"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomFormLabel>{t('EditMatch.teamsResults')}</CustomFormLabel>
                <Box mb={2} mt={4}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}
                  >
                    Team A
                  </Typography>
                  <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={selectedMatch?.teams_1_players?.[0]?.image_url}
                        alt={selectedMatch?.teams_1_players?.[0]?.name || '-'}
                      />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        {selectedMatch?.teams_1_players?.[0]?.name || '-'}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                      {[
                        { score: combinedScoring?.[0]?.team_1 },
                        { score: combinedScoring?.[1]?.team_1 },
                        { score: combinedScoring?.[2]?.team_1 },
                      ].map((set, index) => (
                        <ScoreInput
                          key={index}
                          value={formik.values.teams_results?.[index]?.team_1}
                          type="number"
                          inputProps={{
                            maxLength: 1,
                            pattern: '[0-9]*',
                          }}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 1 && /^[0-9]*$/.test(value)) {
                              e.target.value = value;
                            } else {
                              e.target.value = value.slice(0, 1);
                            }
                            const newResults = formik.values.teams_results || [];
                            newResults[index] = {
                              ...newResults[index],
                              team_1: e.target.value,
                            };
                            formik.setFieldValue('teams_results', newResults);
                          }}
                        />
                      ))}
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={selectedMatch?.teams_1_players?.[1]?.image_url}
                        alt={selectedMatch?.teams_1_players?.[1]?.name || '-'}
                      />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        {selectedMatch?.teams_1_players?.[1]?.name || '-'}
                      </Typography>
                      {selectedMatch?.winner_team === selectedMatch?.tournament_team_1_id && (
                        <EmojiEventsIcon sx={{ ml: 1, color: '#FFD700' }} />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}
                  >
                    Team B
                  </Typography>
                  <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={selectedMatch?.teams_2_players?.[0]?.image_url}
                        alt={selectedMatch?.teams_2_players?.[0]?.name || '-'}
                      />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        {selectedMatch?.teams_2_players?.[0]?.name || '-'}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                      {[
                        { score: combinedScoring?.[0]?.team_2 },
                        { score: combinedScoring?.[1]?.team_2 },
                        { score: combinedScoring?.[2]?.team_2 },
                      ].map((set, index) => (
                        <ScoreInput
                          key={index}
                          value={formik.values.teams_results?.[index]?.team_2}
                          type="number"
                          inputProps={{
                            maxLength: 1,
                            pattern: '[0-9]*',
                          }}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 1 && /^[0-9]*$/.test(value)) {
                              e.target.value = value;
                            } else {
                              e.target.value = value.slice(0, 1);
                            }
                            const newResults = formik.values.teams_results || [];
                            newResults[index] = {
                              ...newResults[index],
                              team_2: e.target.value,
                            };
                            formik.setFieldValue('teams_results', newResults);
                          }}
                        />
                      ))}
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={selectedMatch?.teams_2_players?.[1]?.image_url}
                        alt={selectedMatch?.teams_2_players?.[1]?.name || '-'}
                      />
                      <Typography variant="body1" sx={{ ml: 2 }}>
                        {selectedMatch?.teams_2_players?.[1]?.name || '-'}
                      </Typography>
                      {selectedMatch?.winner_team === selectedMatch?.tournament_team_2_id && (
                        <EmojiEventsIcon sx={{ ml: 1, color: '#FFD700' }} />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Stack direction="row" spacing={2}>
                <Button variant="text" color="error" onClick={onCloseDrawer}>
                  {t('Common.cancel')}
                </Button>
                <Button type="submit" sx={{ marginLeft: 2 }} variant="contained" color={'primary'}>
                  {t('Common.submit')}
                </Button>
              </Stack>
            </Grid>
          </Box>
        </form>
      </BlankCard>
    </Drawer>
  );
};

export default EditMatchForm;
