import React from 'react';
import { Stack, Typography } from '@mui/material';

import ChildCard from 'src/components/shared/ChildCard';
import { IconCalendar, IconCash, IconGenderMale, IconTournament, IconTrophy } from '@tabler/icons';
import { useSelector } from 'src/store/Store';
import useLang from 'src/hooks/useLang';
import useMoment from 'src/views/pages/schedule/hooks/useMoment';
import { t } from 'i18next';

enum Genders {
  MALE = 'male',
  FEMALE = 'female',
}

const IntroCard = () => {
  const selectedTournament = useSelector((state) => state.tournamentReducer.selectedTournament);
  const selectedStadium = useSelector((state) => state.configReducer.selectedStadium);

  const { getTranslatedTitle, getTranslatedCurrency } = useLang();
  const [moment] = useMoment();

  return (
    <ChildCard>
      <Typography fontWeight={600} variant="h4" mb={4}>
        {getTranslatedTitle(selectedTournament, 'name')}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" mb={4}>
        {selectedTournament?.additional_info}
      </Typography>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <IconCalendar size={21} />
        <Typography variant="h6">{`${t('TournamentProfile.registrationDeadline')}: ${moment(
          selectedTournament?.registration_deadline,
        ).format('DD/MM/YYYY')}`}</Typography>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <IconCalendar size="21" />
        <Typography variant="h6">{`${t('TournamentProfile.startDate')}: ${moment(
          selectedTournament?.start_date,
        ).format('DD/MM/YYYY')}`}</Typography>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <IconCalendar size="21" />
        <Typography variant="h6">{`${t('TournamentProfile.endDate')}: ${moment(
          selectedTournament?.end_date,
        ).format('DD/MM/YYYY')}`}</Typography>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <IconGenderMale size="21" />
        <Typography variant="h6">{`${t('TournamentProfile.gender')}: ${selectedTournament?.gender === Genders.MALE
            ? t('TournamentProfile.male')
            : selectedTournament?.gender === Genders.FEMALE
              ? t('TournamentProfile.female')
              : t('TournamentProfile.mixed')
          }`}</Typography>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <IconCash size="21" />
        <Typography variant="h6">{`${t(
          'TournamentProfile.registrationPrice',
        )}: ${getTranslatedCurrency(
          Number(selectedTournament?.price_per_team).toFixed(2),
          getTranslatedTitle(selectedStadium, 'main_currency_label'),
        )}`}</Typography>

      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={4}>
        <IconTournament size="21" />
        <Typography variant="h6">{`${t('TournamentProfile.tournamentType')}: ${getTranslatedTitle(
          selectedTournament?.tournament_type,
          'name',
        )}`}</Typography>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" mb={1}>
        <IconTrophy size="21" />
        <Typography variant="h6">{`${t('TournamentProfile.prizes')}: ${selectedTournament?.prizes
          }`}</Typography>
      </Stack>
    </ChildCard>
  );
};

export default IntroCard;
