import React, { useState } from 'react';

import { Box, Button, MenuItem, Modal, Tooltip, Typography, useTheme } from '@mui/material';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import { useFormik } from 'formik';
import { t } from 'i18next';
import useLang from 'src/hooks/useLang';
import useToast from 'src/hooks/useToast';
import { useExportTeamsMutation } from 'src/services/tournaments';
import { IconDownload } from '@tabler/icons';
import { useSelector } from 'src/store/Store';

const isWidget = window.location.pathname.includes('/widget');

const CategorySelection = () => {
  const [visible, setVisible] = useState(false);

  const { getTranslatedTitle } = useLang();
  const { toastPromise } = useToast();
  const theme = useTheme();

  const [exportTeams] = useExportTeamsMutation();

  const tournamentDetails = useSelector((state) => state.tournamentReducer.selectedTournament);

  const formik = useFormik({
    initialValues: {
      category: -1,
    },
    onSubmit: () => {
      const categoryValue = formik.values.category === -1 ? undefined : formik.values.category;

      toastPromise({
        request: () => {
          return exportTeams({
            tournamentId: Number(tournamentDetails.id),
            category: categoryValue,
          });
        },
        onSuccess: (result: any) => {
          if ('error' in result) {
            throw result.error;
          }
          window.open(result?.data?.url);

          formik.resetForm();

          return t('TournamentTeams.exportSuccess');
        },
        onError: (err) => {
          formik.resetForm();
          return err?.data?.message;
        },
      });
    },
  });

  return (
    <>
      {!isWidget && (
        <Tooltip title={t('TournamentTeams.export')}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<IconDownload width={18} />}
            sx={{ flex: '0.5 0.5 18.5%', ml: 2, height: 43 }}
            onClick={() => {
              setVisible(true);
            }}
          >
            {t('TournamentTeams.export')}
          </Button>
        </Tooltip>
      )}
      {!isWidget && (
        <Modal open={visible} onClose={() => setVisible(false)}>
          <Box
            sx={{
              width: '500px',
              backgroundColor: theme.palette.background.paper,
              padding: 2,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography variant="h6">{t('TournamentTeams.exportTitle')}</Typography>
            <Typography mt={2} variant="body1">
              {t('TournamentTeams.exportMessage')}
            </Typography>
            <CustomSelect
              fullWidth
              id="category"
              sx={{ flex: '0.5 0.5 18.5%', mt: 2 }}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
              {...formik.getFieldProps('category')}
            >
              <MenuItem value={-1}>{t('TournamentTeams.allCategories')}</MenuItem>
              {tournamentDetails?.categories?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {getTranslatedTitle(option, 'name')}
                </MenuItem>
              ))}
            </CustomSelect>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                color="error"
                onClick={() => {
                  formik.resetForm();
                  setVisible(false);
                }}
                sx={{
                  mr: 2,
                }}
              >
                {t('Common.cancel')}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  formik.handleSubmit();
                  setVisible(false);
                }}
              >
                {t('Common.submit')}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CategorySelection;
