import { Match } from '../types';
import { KnockoutStage, KnockoutMatch } from '../mockApiData';

export function convertToSimpleBracket(rounds: KnockoutStage[]): Match[] {
  const matchesMap = new Map<number, Match>();

  rounds.forEach((round) => {
    round.matches.forEach((match: KnockoutMatch) => {
      const newMatch: Match = {
        id: match.id,
        name: `${round.round} - Match`,
        nextMatchId: null,
        nextLooserMatchId: null,
        tournamentRoundText: round.round,
        startTime: match.match_date || null,
        state: determineState(match),
        location: match?.pitch_name || null,
        participants: [
          {
            id: match.tournament_team_1_id?.toString() || '',
            resultText: getTeamResultInString(match, 'team_1'),
            isWinner: match.winner_team === match.tournament_team_1_id,
            status: null,
            name: `${match.teams_1_players?.[0]?.name || ''} ${
              match.teams_1_players?.[1]?.name ? '/' : ' - '
            } ${match.teams_1_players?.[1]?.name || ''}`,
          },
          {
            id: match.tournament_team_2_id?.toString() || '',
            resultText: getTeamResultInString(match, 'team_2'),
            isWinner: match.winner_team === match.tournament_team_2_id,
            status: null,
            name: `${match.teams_2_players?.[0]?.name || ''} ${
              match.teams_2_players?.[1]?.name ? '/' : ' - '
            } ${match.teams_2_players?.[1]?.name || ''}`,
          },
        ],
      };
      matchesMap.set(match.id, newMatch);
    });
  });

  rounds.forEach((round, roundIndex) => {
    if (roundIndex < rounds.length - 1) {
      const nextRound = rounds[roundIndex + 1];
      round.matches.forEach((match: KnockoutMatch) => {
        const currentMatch = matchesMap.get(match.id);
        if (currentMatch) {
          const winnerTeamPlayers =
            match.winner_team === match.tournament_team_1_id
              ? match.teams_1_players
              : match.teams_2_players;

          const winnerPlayerIds = new Set(winnerTeamPlayers.map((player) => player.user_id));

          const nextMatch = nextRound.matches.find((nextMatch) => {
            const nextMatchPlayers = [...nextMatch.teams_1_players, ...nextMatch.teams_2_players];

            return nextMatchPlayers.some((player) => winnerPlayerIds.has(player.user_id));
          });

          if (nextMatch) {
            currentMatch.nextMatchId = nextMatch.id;
          }
        }
      });
    }
  });

  return Array.from(matchesMap.values());
}

function determineState(
  match: KnockoutMatch,
): 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | string {
  if (match.winner_team) {
    return 'SCORE_DONE';
  }
  if (match.winner_team && (!match.tournament_team_1_id || !match.tournament_team_2_id)) {
    return 'WALK_OVER';
  }

  return 'SCHEDULED';
}

const getTeamResultInString = (match: KnockoutMatch, team: 'team_1' | 'team_2') => {
  const team1Result = match.teams_results?.map((result) => result?.[team]);

  return team1Result?.join(' - ');
};
