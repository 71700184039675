import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useAddOrganizerFormValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    users: Yup.array().min(1, t('TournamentOrganizers.usersRequired')),
  });

  return { validationSchema };
};
