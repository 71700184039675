import {
  Avatar,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconPhone, IconPlus } from '@tabler/icons';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useToast from 'src/hooks/useToast';
import { useSelector } from 'src/store/Store';
import DeleteAlert from 'src/components/shared/DeleteAlert';

import './TournamentOrganizers.css';

import {
  useDeleteOrganizerMutation,
  useLazyGetTournamentOrganizersQuery,
} from 'src/services/tournaments';
import { Organizer } from 'src/services/tournaments/getTournamentOrganizers';
import AddEditOrganizerForm from './components/AddOrganizerForm';
import useLang from 'src/hooks/useLang';
import BlankCard from 'src/components/shared/BlankCard';
import getUserImage from 'src/utils/getUserImage';

const isWidget = window.location.pathname.includes('/widget');

const TournamentOrganizers = ({ isLoading }: { isLoading: boolean }) => {
  const { id } = useParams();

  const { toastPromise } = useToast();
  const { getTranslatedTitle } = useLang();

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [selectedTournamentOrganizer, setSelectedTournamentOrganizer] = useState<
    Organizer | undefined
  >(undefined);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const [deleteOrganizerReq] = useDeleteOrganizerMutation();
  const [getTournamentOrganizers, getTournamentOrganizersRes] =
    useLazyGetTournamentOrganizersQuery();

  const tournamentDetails = useSelector((state) => state.tournamentReducer.selectedTournament);

  useEffect(() => {
    loadTournamentOrganizers();
  }, []);

  const loadTournamentOrganizers = () => {
    getTournamentOrganizers({
      id: Number(id),
    });
  };

  const deleteOrganizer = (organizerId: number) => {
    toastPromise({
      request: () => {
        return deleteOrganizerReq({
          id: Number(id),
          user_id: Number(organizerId),
        });
      },
      onSuccess: (result: any) => {
        if ('error' in result) {
          throw result.error;
        }

        loadTournamentOrganizers();

        return t('TournamentOrganizers.organizerDeletedSuccessfully');
      },
      onError: (err) => {
        if (err.data && err.data.errors) {
          const errorMessages = Object.values(err.data.errors).flat().join('. ');

          return errorMessages;
        }

        if (err?.data?.message) {
          return err?.data?.message;
        }

        return t('TournamentOrganizers.errorOccurred');
      },
    });
  };

  const HeaderToolbar = () => {
    return (
      <Box display={'flex'} justifyContent={'space-between'} py={1.5}>
        <Box>
          {isLoading ? (
            <Skeleton sx={{ width: 150 }} variant="text" />
          ) : (
            <Typography variant="h6">{getTranslatedTitle(tournamentDetails, 'name')}</Typography>
          )}
        </Box>

        {!isWidget && (
          <Box>
            {isLoading ? (
              <Skeleton sx={{ width: 250 }} variant="text" />
            ) : (
              <Button
                onClick={() => {
                  setIsAddFormOpen(true);
                }}
                variant="contained"
                color="primary"
                startIcon={<IconPlus width={18} />}
                sx={{ width: undefined, maxWidth: undefined }}
              >
                {t('TournamentOrganizers.addOrganizer')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const rows = getTournamentOrganizersRes?.data?.data || [];

  if (isLoading || getTournamentOrganizersRes?.isLoading || getTournamentOrganizersRes?.isFetching)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
        <CircularProgress color="secondary" sx={{ m: 5 }} />
      </Box>
    );

  return (
    <>
      <Box m={1} flexGrow={1}>
        <HeaderToolbar />
        <Divider sx={{ ml: 2 }} />
      </Box>
      <Grid container spacing={2} mt={0}>
        {rows?.map((organizer) => (
          <Grid item xs={4} key={organizer.id}>
            <BlankCard>
              <CardContent>
                <Stack direction={'row'} gap={2} alignItems="center">
                  <Avatar src={getUserImage(organizer?.id)} alt="Remy Sharp" />
                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      fontWeight="bold"
                    >
                      {organizer?.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <IconPhone size="14" />
                      {organizer.phone_number}
                    </Typography>
                  </Box>
                  {!isWidget && (
                    <Box ml="auto">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setSelectedTournamentOrganizer(organizer);
                          setDeleteAlertOpen(true);
                        }}
                      >
                        {t('Coaches.delete')}
                      </Button>
                    </Box>
                  )}
                </Stack>
              </CardContent>
            </BlankCard>
          </Grid>
        ))}
      </Grid>
      <AddEditOrganizerForm
        open={isAddFormOpen}
        onClose={() => {
          setSelectedTournamentOrganizer(undefined);
          setIsAddFormOpen(false);
        }}
        selectedOrganizer={selectedTournamentOrganizer}
        loadOrganizers={loadTournamentOrganizers}
      />
      <DeleteAlert
        open={deleteAlertOpen}
        handleClose={() => setDeleteAlertOpen(false)}
        title={t('TournamentOrganizers.deleteOrganizer')}
        message={t('TournamentOrganizers.deleteOrganizerMessage')}
        buttons={[
          {
            text: t('Coaches.delete'),
            color: 'error',
            onClick: () => deleteOrganizer(selectedTournamentOrganizer?.id),
          },
          {
            text: t('Coaches.cancel'),
            color: 'primary',
            onClick: () => setDeleteAlertOpen(false),
          },
        ]}
      />
    </>
  );
};

export default TournamentOrganizers;
