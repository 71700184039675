import React from 'react';
import { Box, Button, ButtonBase, Grid, Stack, Typography } from '@mui/material';
import { useSelector } from 'src/store/Store';

import { t } from 'i18next';
import useLang from 'src/hooks/useLang';
import IntroCard from './IntroCard';
import ChildCard from 'src/components/shared/ChildCard';
import {
  IconCalendar,
  IconCategory,
  IconCurrencyDollar,
  IconLocation,
  IconMapPin,
} from '@tabler/icons';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import i18n from 'src/utils/i18n';

const isWidget = window.location.pathname.includes('/widget');

interface IProps {
  setIsApprovalQueueOpen: (isApprovalQueueOpen: boolean) => void;
}

const Details = (props: IProps) => {
  const selectedTournament = useSelector((state) => state.tournamentReducer.selectedTournament);

  const { getTranslatedTitle } = useLang();

  const isRTL = i18n.language === 'ar';

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item sm={12} lg={5} xs={12}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <IntroCard />
          </Grid>
        </Grid>
      </Grid>
      {/* Left Cards */}
      <Grid item sm={12} lg={7} xs={12}>
        {selectedTournament?.sum_categories_max_number_of_teams && (
          <Grid item sm={12}>
            <ChildCard>
              <Typography fontWeight={600} variant="h4" mb={2}>
                {t('TournamentProfile.teamsCount')}
              </Typography>
              <LinearProgressWithLabel
                value={
                  (selectedTournament?.sum_categories_registered_teams_count /
                    selectedTournament?.sum_categories_max_number_of_teams) *
                  100
                }
                total={selectedTournament?.sum_categories_max_number_of_teams}
                registered={selectedTournament?.sum_categories_registered_teams_count}
              />
            </ChildCard>
          </Grid>
        )}
        {selectedTournament?.is_waiting_list && !isWidget && (
          <Grid item sm={12} mt={2}>
            <ButtonBase
              onClick={() => {
                props.setIsApprovalQueueOpen(true);
              }}
              sx={{ width: '100%', textAlign: 'left' }}
            >
              <ChildCard>
                <Typography fontWeight={600} variant="h4" mb={2}>
                  {t('ApprovalQueue.title')}
                </Typography>
                <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" gap={2} alignItems="center">
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        backgroundColor: '#007AFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      <Typography variant="body1" color="white">
                        {selectedTournament?.waiting_list_count}
                      </Typography>
                    </Box>
                    <Typography variant="h6">{t('ApprovalQueue.inQueue')}</Typography>
                  </Stack>
                  {isRTL ? <ArrowLeft fontSize="large" /> : <ArrowRight fontSize="large" />}
                </Stack>
              </ChildCard>
            </ButtonBase>
          </Grid>
        )}

        <Grid item sm={12} mt={2}>
          <ChildCard>
            <Typography fontWeight={600} variant="h4" mb={2}>
              {t('TournamentProfile.categories')}
            </Typography>
            <Stack direction="row" gap={2} alignItems="center" mb={3}>
              <IconCategory size="21" />
              <Typography variant="h6">
                {selectedTournament?.categories
                  ?.map((c) => getTranslatedTitle(c, 'name'))
                  .join(', ')}
              </Typography>
            </Stack>
          </ChildCard>
        </Grid>
        {selectedTournament?.venue && (
          <Grid item sm={12} mt={2}>
            <ChildCard>
              <Typography fontWeight={600} variant="h4" mb={2}>
                {t('TournamentProfile.location')}
              </Typography>
              <Stack
                direction="row"
                justifyContent={'space-between'}
                gap={2}
                alignItems="center"
                mb={3}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <IconLocation size="21" />
                  <Typography variant="h6">{selectedTournament?.venue}</Typography>
                </Stack>
                <Button
                  startIcon={<IconMapPin />}
                  onClick={() => {
                    window.open(
                      `https://maps.google.com/?q=${selectedTournament?.geolat},${selectedTournament?.geolng}`,
                      '_blank',
                    );
                  }}
                  size="small"
                  variant="outlined"
                >
                  {t('TournamentProfile.viewOnMap')}
                </Button>
              </Stack>
            </ChildCard>
          </Grid>
        )}
        <Grid item sm={12} mt={2}>
          <ChildCard>
            <Typography fontWeight={600} variant="h4" mb={2}>
              {t('TournamentProfile.supportedPaymentMethods')}
            </Typography>
            <Stack direction="row" gap={2} alignItems="center" mb={3}>
              <IconCurrencyDollar size="21" />
              <Typography variant="h6">
                {selectedTournament?.supported_payment_methods?.map((p) => p.title).join(', ')}
              </Typography>
            </Stack>
          </ChildCard>
        </Grid>
        {selectedTournament?.daily_timings && (
          <Grid item sm={12} mt={2}>
            <ChildCard>
              <Typography fontWeight={600} variant="h4" mb={2}>
                {t('TournamentProfile.dailyTiming')}
              </Typography>
              <Stack direction="row" gap={2} alignItems="center" mb={3}>
                <IconCalendar size="21" />
                <Typography variant="h6">{selectedTournament?.daily_timings}</Typography>
              </Stack>
            </ChildCard>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Details;
