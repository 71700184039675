import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentTeamsResponse, { tournamentId: number; categoryId: number }>({
    query: ({ tournamentId, categoryId }) => ({
      url: `v4/pitch-owner-app/tournaments/${tournamentId}/teams/${categoryId}`,
      method: 'Get',
    }),
  });

type TournamentTeamsResponse = {
  data: Team[];
};

export interface Team {
  id: number;
  tournament_id: number;
  team_number: number;
  status: number;
  payment_currency: number;
  sub_payment_method: any;
  payment_method: number;
  amount_paid: number;
  payment_status: number;
  price: number;
  team_players: {
    id: number;
    name: string;
    phone: string;
  }[];
  category: {
    id: number;
    name: string;
    name_ar: string;
    max_number_of_teams: number | null;
  };
}

export enum TeamStatus {
  DELETED = 4,
}
