import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, deleteOrganizerBody>({
    query: (body) => ({
      url: `/v4/pitch-owner-app/tournaments/${body.id}/organizers`,
      method: 'delete',
      body: {
        user_id: body.user_id,
      },
    }),
  });

interface deleteOrganizerBody {
  id: number;
  user_id: number;
}
