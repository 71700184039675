import React from 'react';
import { TextField, Box, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomInput = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  width: '40px',
  height: '40px',
  margin: '0 8px',
  '& input': {
    textAlign: 'center',
    fontSize: '16px',
    padding: 7,
    lineHeight: '40px',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
}));

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type ScoreInputProps = TextFieldProps;

const ScoreInput: React.FC<ScoreInputProps> = (props) => {
  return (
    <Container>
      <CustomInput variant="outlined" {...props} />
    </Container>
  );
};

export default ScoreInput;
