import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Skeleton,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router';
import { useLazyGetTournamentScheduleQuery } from 'src/services/tournaments';
import { t } from 'i18next';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import useLang from 'src/hooks/useLang';
import moment from 'moment';
import {
  TournamentScheduleReponse,
  ScheduleMatch,
} from 'src/services/tournaments/getTournamentSchedule';

import MatchItem from './components/MatchItem';
import EditMatch from './components/EditMatch';
import { useSelector } from 'src/store/Store';

const Schedule = ({ isLoading }: { isLoading: boolean }) => {
  const { id } = useParams();
  const { getTranslatedTitle } = useLang();

  const [getTournamentSchedule, getTournamentScheduleRes] = useLazyGetTournamentScheduleQuery();

  const tournamentDetails = useSelector((state) => state.tournamentReducer.selectedTournament);

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [categories, setCategories] = useState<string[]>([]);
  const [schedule, setSchedule] = useState<TournamentScheduleReponse | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [openEditMatch, setOpenEditMatch] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<ScheduleMatch | undefined>(undefined);

  useEffect(() => {
    loadMatches();
  }, [id]);

  const loadMatches = () => {
    getTournamentSchedule({ id: Number(id) });
  };

  useEffect(() => {
    if (getTournamentScheduleRes?.data) {
      setSchedule(getTournamentScheduleRes?.data);
      const categoriesOutput = Object.keys(getTournamentScheduleRes.data?.data);
      setCategories(categoriesOutput);
      setSelectedCategory(categoriesOutput[0]);
    }
  }, [getTournamentScheduleRes?.data]);

  const data = useMemo(() => {
    return schedule && selectedCategory ? schedule?.data?.[selectedCategory] : null;
  }, [schedule, selectedCategory]);

  const sectionsData = useMemo(() => {
    if (!data?.tournament_match_groups?.[selectedGroup]?.tournament_matches) return [];
    const dataOfGroup: ScheduleMatch[] =
      data.tournament_match_groups[selectedGroup].tournament_matches;

    const liveMatches: ScheduleMatch[] = [];
    const upcomingMatches: ScheduleMatch[] = [];
    const pastMatches: ScheduleMatch[] = [];

    const finalData: (ScheduleMatch | string)[] = [];

    dataOfGroup.forEach((item: ScheduleMatch) => {
      const dateTime = moment(
        `${item.match_date} ${item.match_time}`,
        'YYYY-MM-DD hh:mm:ss',
      ).unix();
      item = { ...item, dateTime };

      if (item.is_live && !item.winner_team) {
        liveMatches.push(item);
      } else if (!item.is_live && !item.winner_team) {
        upcomingMatches.push(item);
      } else if (item.winner_team) {
        pastMatches.push(item);
      }
    });

    liveMatches.sort((a, b) => a.dateTime - b.dateTime);
    upcomingMatches.sort((a, b) => a.dateTime - b.dateTime);
    pastMatches.sort((a, b) => a.dateTime - b.dateTime);

    if (liveMatches?.length) {
      finalData.push(t('TournamentScheduleTab.live'), ...liveMatches);
    }
    if (upcomingMatches?.length) {
      finalData.push(t('TournamentScheduleTab.upcoming'), ...upcomingMatches);
    }
    if (pastMatches?.length) {
      finalData.push(t('TournamentScheduleTab.past'), ...pastMatches);
    }

    return finalData;
  }, [data, selectedCategory, selectedGroup]);

  // const renderCategory = (item: string, index: number) => {
  //   const categoryData = schedule?.[item];

  //   return (
  //     <MenuItem key={index} value={item} onClick={() => setSelectedCategory(item)}>
  //       {categoryData?.category?.name}
  //     </MenuItem>
  //   );
  // };

  const renderGroup = (item: any, index: number) => {
    return (
      <MenuItem key={index} value={index} onClick={() => setSelectedGroup(index)}>
        {item.is_single_elimination
          ? t('TournamentScheduleTab.knockout')
          : t('TournamentScheduleTab.group', { value: index + 1 })}
      </MenuItem>
    );
  };

  const Row = ({ row }: { row: ScheduleMatch }) => {
    return (
      <MatchItem
        row={row}
        onEdit={() => {
          setSelectedMatch(row);
          setOpenEditMatch(true);
        }}
      />
    );
  };

  const HeaderToolbar = () => (
    <Toolbar style={{ padding: 0 }}>
      <Box sx={{ flex: '1 1 100%' }}>
        <Typography variant="h6">{getTranslatedTitle(tournamentDetails, 'name')}</Typography>
      </Box>

      {isLoading || getTournamentScheduleRes?.isLoading || getTournamentScheduleRes?.isFetching ? (
        <Skeleton sx={{ width: 250 }} variant="text" />
      ) : categories?.length > 0 ? (
        <CustomSelect
          id="category_id"
          // fullWidth
          onChange={(e: any) => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          sx={{
            marginRight: 1
          }}
        >
          {categories?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
      ) : (
        <></>
      )}

      {data?.tournament_match_groups?.length > 1 && (
        <CustomSelect
          id="group_id"

          onChange={(e: any) => setSelectedGroup(e.target.value)}
          value={selectedGroup}
        // sx={{ flex: '0.15 0.15 20%' }}
        >
          {data?.tournament_match_groups?.map(renderGroup)}
        </CustomSelect>
      )}
    </Toolbar>
  );

  if (isLoading || getTournamentScheduleRes?.isLoading || getTournamentScheduleRes?.isFetching)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
        <CircularProgress color="secondary" sx={{ m: 5 }} />
      </Box>
    );

  return (
    <Grid display={'flex'} flexDirection={'column'} flex={1}>
      <Box m={0} flexGrow={1}>
        <HeaderToolbar />
        <Divider sx={{ mb: 0 }} />
      </Box>

      <Grid flexDirection={'row'} display={'flex'} flexWrap={'wrap'} style={{ padding: 0 }}>
        {sectionsData?.map((item, index) => {
          if (typeof item === 'string') {
            return (
              <TableRow key={index} style={{ width: "100%", marginTop: 10, marginBottom: 10 }}>
                <TableCell style={{ padding: 0, margin: 10, paddingBottom: 15 }}>
                  <Typography variant="h6">{item}</Typography>
                </TableCell>
              </TableRow>
            );
          }

          return <Row key={item.id} row={item} />;
        })}
      </Grid>

      <EditMatch
        open={openEditMatch}
        onClose={() => {
          setSelectedMatch(undefined);
          setOpenEditMatch(false);
        }}
        selectedMatch={selectedMatch}
        loadMatches={loadMatches}
      />
    </Grid>
  );
};

export default Schedule;
