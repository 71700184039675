//`v4/pitch-owner-app/tournaments/${TournamentId}/matches/${matchId}`
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, editMatchBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.tournamentId}/matches/${body.matchId}`,
      method: 'PUT',
      body: body.body,
    }),
  });

 type editMatchBody = {
  tournamentId: number;
  matchId: number;
  body: EditMatchBody;
};

export type EditMatchBody = {
  match_date: string;
  match_time: string;
  pitch_name: string;
  stream_url: string;
  is_live: 1 | 0;
  set_end_score: string;
  teams_results: {
    team_1: string;
    team_2: string;
    winner_team: 'team_1' | 'team_2' | '';
  }[];
};
