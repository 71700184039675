import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'src/store/Store';

const useLang = () => {
  const activeDir = useSelector((state) => state.customizer.activeDir);

  const isRTL = activeDir === 'rtl';

  const { t } = useTranslation();

  const getTranslatedTitle = (data: any, key: string) => {
    if (!isRTL && data?.[`${key}_en`]) {
      return data?.[`${key}_en`];
    }

    return isRTL && data?.[`${key}_ar`] ? data?.[`${key}_ar`] : data?.[key];
  };

  const getARandENPagination = (from: number, to: number, count: number) => {
    const arabicFormatter = new Intl.NumberFormat('ar-EG');
    if (!isRTL) {
      return `${from}-${to} ${t('pagination.displayedRows')} ${count}`;
    }

    return `${arabicFormatter.format(from)}-${arabicFormatter.format(to)} ${t(
      'pagination.displayedRows',
    )} ${arabicFormatter.format(count)}`;
  };

  const getTranslatedCurrency = (value: number | string, currency: string) => {
    if (!isRTL) {
      return `${currency} ${value}`;
    }

    return `${value} ${currency}`;
  };

  return {
    getTranslatedTitle,
    getARandENPagination,
    getTranslatedCurrency,
  };
};

export default useLang;
