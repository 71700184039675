import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { PaymentMethods } from './addTournament';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<packagesListResponse, packagesListBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments?status=${body.status}&limit=${body.limit}&page=${body.page}`,
      method: 'Get',
    }),
  });

export interface packagesListBody {
  status: TournamentsStatus;
  limit: number;
  page: number;
}

export enum TournamentsStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
}

export interface packagesListResponse {
  data: Tournament[];
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
    total?: number;
  };
}

export interface TournamentPlayer {
  user_id: number;
  image_url: string;
}

export interface Tournament {
  id: number;
  name: string;
  name_ar: string;
  start_date: string;
  end_date: string;
  venue: string;
  geolat: string;
  geolng: string;
  teams_per_group: number;
  teams_to_qualify_per_group: number;
  is_live: 0 | 1;
  is_global: 0 | 1;
  is_private: 0 | 1;
  price_per_team: string;
  currency: string;
  registration_deadline: string;
  gender: string;
  prizes: string;
  additional_info: string | null;
  daily_timings: string | null;
  players_count_per_team: number | null;
  map_url: string | null;
  set_end_score: number | null;
  country: {
    id: number;
    name: string;
    name_ar: string;
    code: string;
    visible: number;
    phone_code: string;
    iso_code: string;
    notes: string | null;
  };
  sport_type: {
    id: number;
    name_en: string;
    name_ar: string;
  };
  tournament_type: {
    id: number;
    name: string;
    name_ar: string;
  };
  supported_payment_methods: [
    {
      id: PaymentMethods;
      tag: string;
      title: string;
    },
  ];
  sum_categories_max_number_of_teams: number;
  sum_categories_registered_teams_count: number;
  registered_teams_meta_info: TournamentPlayer[];
  categories: {
    id: number;
    name: string;
    name_ar: string;
    max_number_of_teams: number | null;
    registered_teams_count: number | null;
  }[];
}

export enum CategoriesGender {
  female = 'female',
  male = 'male',
  mixed = 'mixed',
}
