import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentWaitingListResponse, { id: number; status: number }>({
    query: (body) => ({
      url: `v4/pitch-owner/tournaments/${body.id}/waiting-list?status=${body.status}`,
      method: 'Get',
    }),
  });

export type TournamentWaitingListResponse = {
  data: TournamentWaitingListData[];
};

export interface TournamentWaitingListData {
  id: number;
  tournament_id: number;
  team_number: number;
  status: number;
  payment_currency: string;
  sub_payment_method: any;
  payment_method: number;
  amount_paid: number;
  payment_status: number;
  price: number;
  team_players: {
    id: number;
    name: string;
    phone: number;
  }[];
  category: {
    id: number;
    name: string;
    name_ar: string;
    max_number_of_teams: number | null;
  };
}
