import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<TournamentWaitingListResponse, AcceptOrRejectWaitingListRequest>({
    query: (body) => ({
      url: `v4/pitch-owner/tournaments/${body.id}/teams/${body.teamId}/accept-reject`,
      method: 'Post',
      body: body.body,
    }),
  });

type AcceptOrRejectWaitingListRequest = {
  id: number;
  teamId: number;
  body: {
    accept: 1 | 0;
    rejection_reason: string;
  };
};

export type TournamentWaitingListResponse = {
  message: string;
};
