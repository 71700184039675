import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentStandingsData, { id: number }>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/list-standings`,
      method: 'Get',
    }),
  });

export type Record = {
  id: number;
  tournament_id: number;
  category_id: number;
  team_number: number;
  points: string | null;
  wins: number;
  loss: number;
  played: number;
  team_player_names: string;
};

export type Round = {
  id: number;
  match_date: string;
  pitch_name: string;
  round: string;
  team_1_number: number;
  team_1_player_names: string;
  team_2_number: number;
  team_2_player_names: string;
  tournament_team_1_id: number;
  tournament_team_2_id: number;
  winner_team: number;
};

export type TournamentMatches = {
  tournament_matches: {
    Quarterfinals?: Round[];
    Semifinals?: Round[];
    Finals?: Round[];
  };
};

export type Standings = {
  tournament_standing_groups: Record[] | TournamentMatches[];
};

export type Standing = {
  'Category A'?: Standings[];
  'Category B'?: Standings[];
  'Category C'?: Standings[];
  'Category D'?: Standings[];
};

export type TournamentStandingsData = {
  data: Standing;
};
