import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, addOrganizerBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/organizers`,
      method: 'POST',
      body: {
        users_ids: body.users_ids,
      },
    }),
  });

type addOrganizerBody = {
  id: number;
  users_ids: number[];
};
