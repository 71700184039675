import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface DeleteAlertProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  message: string;
  buttons: { text: string; color: 'primary' | 'error'; onClick: () => void }[];
}

const DeleteAlert = ({ open, handleClose, title, message, buttons }: DeleteAlertProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { minWidth: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map((button, index) => (
          <Button
            key={index}
            color={button.color || 'primary'}
            onClick={() => {
              if (button.onClick) {
                button.onClick();
              }
              handleClose();
            }}
          >
            {button.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAlert;
