import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentCategoriesResponse, any>({
    query: () => ({
      url: 'v4/lookup/tournament-categories',
      method: 'Get',
    }),
  });

type TournamentCategoriesResponse = {
  data: TournamentCategory[];
};

export type TournamentCategory = {
  id: number;
  name: string;
  name_ar: string;
  max_number_of_teams?: string;
};
