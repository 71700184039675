import React from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import Close from '@mui/icons-material/Close';

interface BookingFormHeaderProps {
  onClose: () => void;
  title: string;
}

const BookingFormHeader: React.FC<BookingFormHeaderProps> = ({ onClose, title }) => {
  const { palette } = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        justifyContent: 'space-between',
        backgroundColor: palette.primary.light,
        borderRadius:0
      }}
    >
      <Typography variant="h4" color={palette.text.primary}>
        {title}
      </Typography>
      <IconButton onClick={onClose} className="booking-form-close-button">
        <Close />
      </IconButton>
    </Box>
  )
}

export default BookingFormHeader;
