import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentOrganizerData, { id: number }>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/organizers`,
      method: 'Get',
    }),
  });

export interface TournamentOrganizerData {
  data: Organizer[];
  links: {
    first: string;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
}

export interface Organizer {
  id: number;
  name: string;
  phone_number: number;
}
