import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, deleteDiscountBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}/delete`,
      method: 'delete',
    }),
  });

interface deleteDiscountBody {
  id: number;
}
