import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'src/store/Store';

import useLang from 'src/hooks/useLang';

import { SingleEliminationBracket, SVGViewer } from '@g-loot/react-tournament-brackets';
import useWindowSize from './Hooks/useWindowSize';
import { KnockoutStage } from './mockApiData';
import { convertToSimpleBracket } from './Helpers/convertApiDataToViewData';
import MatchCard from './Components/MatchCard';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';
import { useLazyGetTournamentKnockoutsQuery } from 'src/services/tournaments';
import useMoment from 'src/views/pages/schedule/hooks/useMoment';
import { t } from 'i18next';
import { isRtl } from 'src/utils/isRtl';

const TournamentKnockoutTab = () => {
  const [moment] = useMoment();

  const rtl = isRtl();

  const englishRowJustification = rtl ? 'row-reverse' : 'row';

  const tournament = useSelector((state) => state.tournamentReducer.selectedTournament);
  const tournamentId = tournament?.id;

  const [getTournamentKnockouts, { isLoading, isFetching, data }] =
    useLazyGetTournamentKnockoutsQuery();

  const { getTranslatedTitle } = useLang();

  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [stages, setStages] = useState<KnockoutStage[] | undefined>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const containerOption = useSelector((state) => state.customizer.isLayout);
  const isFull = containerOption === 'full';

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const stagesOfCategory = data?.data?.[selectedCategory]?.knockouts?.rounds;
      setStages(stagesOfCategory);
    }
  }, [selectedCategory, data]);

  const getData = async () => {
    const result = await getTournamentKnockouts({ id: tournamentId });
    const responseData = result?.data?.data;
    if (responseData && typeof responseData === 'object') {
      const categoriesData = Object.keys(responseData);
      setCategories(categoriesData);
      if (categoriesData?.length !== 0) {
        setSelectedCategory(categoriesData[0]);
      }
    }
  };

  const HeaderToolbar = () => (
    <Box display={'flex'} flexDirection={'row'} mt={1} alignItems={'center'}>
      <Box sx={{ flex: '1 1 100%' }}>
        {isLoading ? (
          <Skeleton sx={{ width: 150 }} variant="text" />
        ) : (
          <Typography variant="h6">{getTranslatedTitle(tournament, 'name')}</Typography>
        )}
      </Box>
      {isLoading || isFetching ? (
        <Skeleton sx={{ width: 250 }} variant="text" />
      ) : categories?.length > 0 ? (
        <CustomSelect
          id="category_id"
          fullWidth
          onChange={(e: any) => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          sx={{ flex: '0.25 0.25 15%', backgroundColor: 'white' }}
        >
          {categories?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
      ) : (
        <></>
      )}
    </Box>
  );

  const [width, height] = useWindowSize();
  const finalWidth = Math.max(isFull ? width * 0.81 : width * 0.68, 500);
  const finalHeight = Math.max(isFull ? height * 0.47 : height * 0.5, 500);

  if (isLoading || isFetching) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
        <CircularProgress color="secondary" sx={{ m: 5 }} />
      </Box>
    );
  }

  if (convertToSimpleBracket(stages).length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ overflowX: 'hidden', width: '100%' }}>
      <HeaderToolbar />
      <Divider sx={{ mt: 1 }} />

      <Stack
        direction={englishRowJustification}
        sx={{ mt: 2 }}
        overflow={'scroll'}
        mr={rtl ? 4 : 0}
      >
        {stages?.map((stage, index) => (
          <Stack
            width={350}
            flexDirection={englishRowJustification}
            justifyContent={stage?.round_start_date ? 'space-between' : 'center'}
            alignItems={'center'}
            key={index}
            px={6}
          >
            <Typography variant="h6">{t(`TournamentScheduleTab.${stage?.round}`)}</Typography>
            {stage?.round_start_date && (
              <Typography variant="h6">
                {moment(stage?.round_start_date).format('DD/MM/YYYY')}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>

      <Box sx={{ overflowX: 'scroll', overflowY: 'scroll' }}>
        <SingleEliminationBracket
          matches={convertToSimpleBracket(stages).reverse()}
          matchComponent={({ match }) => {
            return <MatchCard match={match} />;
          }}
          svgWrapper={({ children, ...props }) => {
            return (
              <SVGViewer width={finalWidth} height={finalHeight} {...props}>
                {children}
              </SVGViewer>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default TournamentKnockoutTab;
