import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Theme,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { t } from 'i18next';
import { useAddTeamMutation, useEditTeamMutation } from 'src/services/tournaments';
import useToast from 'src/hooks/useToast';
import CustomFormLabel from 'src/components/forms/theme-elements/CustomFormLabel';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import BlankCard from 'src/components/shared/BlankCard';
import { Drawer, DrawerHeader } from 'src/components/shared';
import { Team } from 'src/services/tournaments/getTournamentTeams';
import useValidationSchema from './AddEditTeamFormValidationSchema';
import { countryCodes } from '../../schedule/helpers/getCountryCodes';
import CustomSelect from 'src/components/forms/theme-elements/CustomSelect';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedTeam?: Team;
  loadTeams: () => void;
  tournamentId: number;
  categoryId: number;
}

const AddEditTeamForm = (props: Props) => {
  const { open, onClose, selectedTeam, loadTeams, tournamentId, categoryId } = props;

  const [phonePlaceholder, setPhonePlaceholder] = React.useState(countryCodes[0].placeholder);

  const [addTeam] = useAddTeamMutation();
  const [editTeam] = useEditTeamMutation();

  const { toastPromise } = useToast();
  const { validationSchema } = useValidationSchema();

  const formik = useFormik({
    initialValues: {
      players: [
        { name: '', phone: '' },
        { name: '', phone: '' },
      ],
      payment_status: 0, // Default to not paid
      payment_method: '1', // Always send '1'
      country_code_1: countryCodes[0].value,
      country_code_2: countryCodes[0].value,
    },
    validationSchema,
    onSubmit: (values) => {
      const isEdit = Boolean(selectedTeam?.id);
      toastPromise({
        request: () => {
          const players = values.players.map((player, index) => ({
            name: player.name,
            phone: `${values[`country_code_${index + 1}`]?.slice(1)}${player.phone}`,
          }));

          if (isEdit) {
            return editTeam({
              body: { ...values, players },
              tournamentId,
              categoryId,
              teamId: selectedTeam.id,
            });
          }

          return addTeam({ body: { ...values, players }, tournamentId, categoryId });
        },
        onSuccess: (result) => {
          if ('error' in result) {
            throw result.error;
          }
          formik.resetForm();
          onClose();
          loadTeams();

          return isEdit
            ? t('TournamentTeams.teamUpdatedSuccessfully')
            : t('TournamentTeams.teamAddedSuccessfully');
        },
        onError: (err) => {
          if (err.data && err.data.errors) {
            const errorMessages = Object.values(err.data.errors).flat().join('. ');

            return errorMessages;
          }

          if (err?.data?.message) {
            return err?.data?.message;
          }

          return err?.data?.message || t('TournamentTeams.errorOccurred');
        },
      });
    },
  });

  useEffect(() => {
    if (selectedTeam) {
      formik.setValues({
        players: selectedTeam.team_players
          ? selectedTeam.team_players.map((player) => ({
              name: player.name,
              phone: player.phone?.toString()?.slice(3),
            }))
          : [
              { name: '', phone: '' },
              { name: '', phone: '' },
            ],
        payment_status: selectedTeam.payment_status,
        payment_method: '1',
        country_code_1: `+${
          selectedTeam.team_players[0]?.phone?.toString()?.slice(0, 3) || countryCodes[0].value
        }`,
        country_code_2: `+${
          selectedTeam.team_players[1]?.phone?.toString()?.slice(0, 3) || countryCodes[0].value
        }`,
      });
    }
  }, [selectedTeam]);

  const handleCountryCodeChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const selectedCode = event.target.value as string;
    formik.setFieldValue(`country_code_${index + 1}`, selectedCode);

    const selectedCountry = countryCodes.find((country) => country.value === selectedCode);
    setPhonePlaceholder(selectedCountry?.placeholder || '');
    formik.setFieldValue('phone_number', '');
  };

  const onCloseDrawer = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '50%',
          padding: '20px',
        },
      }}
      anchor="right"
      open={open}
      onClose={onCloseDrawer}
    >
      <BlankCard>
        <DrawerHeader
          title={selectedTeam?.id ? t('TournamentTeams.editTeam') : t('TournamentTeams.addTeam')}
          onClose={onCloseDrawer}
        />
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ paddingBottom: '40px', paddingRight: 5, paddingLeft: 5 }}>
            <Grid container spacing={2}>
              {formik.values.players.map((player, index) => (
                <>
                  <Grid item xs={6} key={index}>
                    <CustomFormLabel htmlFor={`players[${index}].name`}>
                      {t(`TournamentTeams.player${index + 1}Name`)}
                    </CustomFormLabel>
                    <CustomTextField
                      id={`players[${index}].name`}
                      fullWidth
                      {...formik.getFieldProps(`players[${index}].name`)}
                      error={formik.touched.players?.[index]?.name && !player.name}
                      helperText={
                        formik.touched.players?.[index]?.name && !player.name
                          ? t('TournamentTeams.playerNameRequired')
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomFormLabel htmlFor={`players[${index}].phone`}>
                      {t(`TournamentTeams.player${index + 1}Phone`)}
                    </CustomFormLabel>
                    <CustomTextField
                      id="phone_number"
                      fullWidth
                      placeholder={phonePlaceholder}
                      {...formik.getFieldProps(`players[${index}].phone`)}
                      error={formik.touched.players?.[index]?.phone && !player.phone}
                      helperText={
                        formik.touched.players?.[index]?.phone && !player.phone
                          ? t('TournamentTeams.playerPhoneRequired')
                          : ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className="country-code-select">
                            <CustomSelect
                              id="country_code"
                              value={formik.values[`country_code_${index + 1}`]}
                              disableUnderline
                              onChange={(e) => handleCountryCodeChange(e, index)}
                              sx={(theme: Theme) => ({
                                '& .MuiSelect-select': {
                                  border: 'none',
                                  paddingRight: '24px',
                                  borderRight: `1px solid ${theme.palette.grey[300]}`,
                                  borderRightWidth: 1,
                                  borderRadius: 0,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                '& .MuiSelect-icon': {
                                  right: 0,
                                },
                              })}
                            >
                              {countryCodes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              ))}
              <Grid item xs={12}>
                <CustomFormLabel htmlFor="payment_status">
                  {t('TournamentTeams.paymentStatus')}
                </CustomFormLabel>
                <Checkbox
                  checked={formik.values.payment_status === 1}
                  onChange={(e) => formik.setFieldValue('payment_status', e.target.checked ? 1 : 0)}
                />
                {t('TournamentTeams.paid')}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button variant="text" color="error" onClick={onCloseDrawer}>
                    {t('Common.cancel')}
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    {t('Common.submit')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </BlankCard>
    </Drawer>
  );
};

export default AddEditTeamForm;
