import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentKnockoutsReponse, { id: number }>({
    query: (body) => ({
      url: `v4/pitch-owner/tournaments/${body.id}/knockout`,
      method: 'Get',
    }),
  });

export type TournamentKnockoutsReponse = {
  data: {
    [key: string]: {
      category: {
        id: number;
        name: string;
        name_ar: string;
      };
      knockouts: {
        id: number;
        round_count: number;
        rounds: KnockoutStage[];
      };
    };
  };
};

export type KnockoutStage = {
  matches: KnockoutMatch[];
  round: string;
  round_start_date: string;
};

export type KnockoutMatch = {
  current_set: number;
  id: number;
  is_live: number;
  match_date: string;
  match_meta_info: any;
  match_time: string;
  pitch_name: string;
  round: string;
  stream_url: string;
  teams_1_players: {
    image_url: string;
    is_has_profile: boolean;
    name: string;
    profile_id: number;
    user_id: number;
    ranking: number;
  }[];
  teams_2_players: {
    image_url: string;
    is_has_profile: boolean;
    name: string;
    profile_id: number;
    user_id: number;
    ranking: number;
  }[];
  teams_results: {
    team_1: string;
    team_2: string;
  }[];
  tournament_team_1_id: number;
  tournament_team_1_live_score: any;
  tournament_team_2_id: number;
  tournament_team_2_live_score: any;
  winner_team: number;
};
