import { api } from 'src/services';
import getTournaments from './getTournaments';
import deleteTournament from './deleteTournament';
import addTournament from './addTournament';
import editTournament from './editTournament';
import getTournamentCategories from './getTournamentCategories';
import getTournamentDetails from './getTournamentDetails';
import getTournamentOrganizers from './getTournamentOrganizers';
import deleteOrganizer from './deleteOrganizer';
import searchUsers from './searchUsers';
import addOrganizer from './addOrganizer';
import deleteTeam from './deleteTeam';
import editTeam from './editTeam';
import getTeams from './getTournamentTeams';
import addTeam from './addTeam';
import getTournamentStandings from './getTournamentStandings';
import getTournamentSchedule from './getTournamentSchedule';
import editMatch from './editMatch';
import getTournamentKnockouts from './getTournamentKnockouts';
import exportTeams from './exportTeams';
import getTournamentWaitingList from './getTournamentWaitingList';
import acceptOrRejectWaitingListRequest from './acceptOrRejectWaitingListRequest';

export const tournamentsApis = api.injectEndpoints({
  endpoints: (build) => ({
    getTournaments: getTournaments(build),
    deleteTournament: deleteTournament(build),
    addTournament: addTournament(build),
    editTournament: editTournament(build),
    getTournamentCategories: getTournamentCategories(build),
    getTournamentDetails: getTournamentDetails(build),
    getTournamentOrganizers: getTournamentOrganizers(build),
    deleteOrganizer: deleteOrganizer(build),
    searchUsers: searchUsers(build),
    addOrganizer: addOrganizer(build),
    deleteTeam: deleteTeam(build),
    editTeam: editTeam(build),
    getTeams: getTeams(build),
    addTeam: addTeam(build),
    getTournamentStandings: getTournamentStandings(build),
    getTournamentSchedule: getTournamentSchedule(build),
    editMatch: editMatch(build),
    getTournamentKnockouts: getTournamentKnockouts(build),
    exportTeams: exportTeams(build),
    getTournamentWaitingList: getTournamentWaitingList(build),
    acceptOrRejectWaitingListRequest: acceptOrRejectWaitingListRequest(build),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetTournamentsQuery,
  useDeleteTournamentMutation,
  useAddTournamentMutation,
  useEditTournamentMutation,
  useLazyGetTournamentCategoriesQuery,
  useLazyGetTournamentDetailsQuery,
  useLazyGetTournamentOrganizersQuery,
  useDeleteOrganizerMutation,
  useSearchUsersMutation,
  useAddOrganizerMutation,
  useDeleteTeamMutation,
  useEditTeamMutation,
  useLazyGetTeamsQuery,
  useAddTeamMutation,
  useLazyGetTournamentStandingsQuery,
  useLazyGetTournamentScheduleQuery,
  useEditMatchMutation,
  useLazyGetTournamentKnockoutsQuery,
  useExportTeamsMutation,
  useLazyGetTournamentWaitingListQuery,
  useAcceptOrRejectWaitingListRequestMutation,
} = tournamentsApis;
