import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, addTeamBody>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.tournamentId}/teams/${body.categoryId}`,
      method: 'POST',
      body: body.body,
    }),
  });

type addTeamBody = {
  tournamentId: number;
  categoryId: number;
  body: {
    players: { name: string; phone: string }[];
    payment_method: string;
    payment_status: number;
  };
};
