import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<TournamentDetailsResponse, { id: number }>({
    query: (body) => ({
      url: `v4/pitch-owner-app/tournaments/${body.id}`,
      method: 'Get',
    }),
  });

type TournamentDetailsResponse = {
  data: TournamentDetailsData;
};

export interface TournamentDetailsData {
  id: number;
  name: string;
  name_ar: string;
  start_date: string;
  end_date: string;
  venue: string;
  geolat: string;
  geolng: string;
  teams_per_group: number | string;
  teams_to_qualify_per_group: number | string;
  is_live: boolean;
  is_private: boolean;
  is_global: boolean;
  price_per_team: string;
  currency: string;
  registration_deadline: string;
  gender: string;
  prizes: string;
  additional_info: string;
  daily_timings: string;
  players_count_per_team: number;
  map_url: string;
  set_end_score: number;
  country: {
    id: number;
    name: string;
    name_ar: string;
    code: string;
    visible: boolean;
    phone_code: string;
    iso_code: string;
    notes: string;
  };
  sport_type: {
    id: number;
    name_en: string;
    name_ar: string;
  };
  tournament_type: {
    id: number;
    name: string;
    name_ar: string;
  };
  supported_payment_methods: [
    {
      id: number;
      tag: string;
      title: string;
    },
  ];
  categories: {
    id: number;
    name: string;
    name_ar: string;
    max_number_of_teams: number;
  }[];
  sum_categories_max_number_of_teams?: number;
  sum_categories_registered_teams_count?: number;
  rank_type: {
    id: TournamentRankType;
    name: string;
    name_ar: string;
  };
  is_waiting_list?: boolean;
  waiting_list_count?: number;
  tournament_image?: string;
}

export enum TournamentRankType {
  MALAEB = 1,
  BF = 2,
  GENERAL = 3,
}
