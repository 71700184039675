export const formatPlayersArray = (
  players: {
    id: number;
    name: string;
    phone: string;
  }[],
) => {
  if (players?.length < 2) {
    return players.concat(
      Array(2 - players.length).fill({
        name: '-',
        phone: '-',
        id: 0,
      }),
    );
  }

  return players;
};
