import { Box, Button, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React from 'react';
import { Drawer, DrawerHeader } from 'src/components/shared';
import BlankCard from 'src/components/shared/BlankCard';
import SearchUserInput from './SearchUserInput/SearchUserInput';
import CustomFormLabel from 'src/components/forms/theme-elements/CustomFormLabel';
import { useAddOrganizerFormValidationSchema } from './addOrganizerFormValidationSchema';
import { useAddOrganizerMutation } from 'src/services/tournaments';
import useToast from 'src/hooks/useToast';
import { useParams } from 'react-router';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedOrganizer: any;
  loadOrganizers: () => void;
};

const AddEditOrganizerForm = (props: Props) => {
  const { id } = useParams();

  const { validationSchema } = useAddOrganizerFormValidationSchema();

  const [addOrganizer] = useAddOrganizerMutation();

  const { toastPromise } = useToast();

  const formik = useFormik({
    initialValues: {
      users: [],
      users_ids: [],
    },
    onSubmit: (values) => {
      toastPromise({
        request: () => {
          return addOrganizer({ users_ids: values.users_ids, id: Number(id) });
        },
        onSuccess: (result) => {
          if ('error' in result) {
            throw result.error;
          }

          onCloseDrawer();
          props.loadOrganizers();

          return t('TournamentOrganizers.organizerAddedSuccessfully');
        },
        onError: (err) => {
          if (err.data && err.data.errors) {
            const errorMessages = Object.values(err.data.errors).flat().join('. ');

            return errorMessages;
          }

          if (err?.data?.message) {
            return err?.data?.message;
          }

          return t('TournamentOrganizers.organizerAdditionFailed');
        },
      });
    },
    validationSchema,
  });

  const onCloseDrawer = () => {
    props.onClose();
    formik.resetForm();
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '50%',
          padding: '20px',
        },
      }}
      className="booking-form-drawer"
      anchor="right"
      open={props.open}
      onClose={onCloseDrawer}
    >
      <BlankCard className="booking-form-card">
        <DrawerHeader
          title={
            props.selectedOrganizer?.id
              ? t('TournamentOrganizers.editOrganizer')
              : t('TournamentOrganizers.addOrganizer')
          }
          onClose={onCloseDrawer}
        />
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              height: 'calc(100vh - 100px)',
              overflow: 'auto',
              paddingBottom: '40px',
              paddingRight: 5,
              paddingLeft: 5,
            }}
            className="booking-form-content"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomFormLabel>{t('CouponsScreen.selectUsers')}</CustomFormLabel>
                <SearchUserInput formik={formik} />
              </Grid>
              <Grid item xs={12} mt={5}>
                <Stack direction="row" spacing={2}>
                  <Button variant="text" color="error" onClick={onCloseDrawer}>
                    {t('Common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    sx={{ marginLeft: 2 }}
                    variant="contained"
                    color={'primary'}
                  >
                    {t('AddCoachPage.submit')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </BlankCard>
    </Drawer>
  );
};

export default AddEditOrganizerForm;
