import { Drawer } from '@mui/material';
import { ReactNode } from 'react';

interface DrawerViewProps {
  children: ReactNode;
  open: boolean;
  onClose: (open: boolean) => void;
}

const DrawerView: React.FC<DrawerViewProps & React.ComponentProps<typeof Drawer>> = ({
  children,
  open,
  onClose,
  ...props
}) => {
  const toggleDrawer = () => {
    onClose(!open);
  };

  return (
    <Drawer open={open} onClose={toggleDrawer} {...props}>
      {children}
    </Drawer>
  );
};

export default DrawerView;
