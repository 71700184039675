import {
  Standing,
  Standings,
  Record,
  Round,
  TournamentMatches,
} from 'src/services/tournaments/getTournamentStandings';

export const categorize = (data: Standing) => {
  return Object.keys(data);
};

export const formatStanding = (item: any) => {
  if (item && !Array.isArray(item)) {
    return Object?.values(item);
  }

  return item;
};

export const formatGroupTable = (group: any) => {
  try {
    const output = [];

    let groups: any[];

    if (group.tournament_standing_groups) {
      groups = group?.tournament_standing_groups;
    } else {
      const typedGroup = group as { [key: string]: { tournament_standing_groups: any[] } };
      groups = Object.entries(typedGroup)[0][1]?.tournament_standing_groups;
    }
    for (let i = 0; i < groups?.length; i++) {
      const item = groups[i];
      let formattedRecord = [];
      formattedRecord = [i + 1 + '.', item.team_player_names, item.played, item.wins, item.loss];
      output.push(formattedRecord);
    }

    return output;
  } catch (err) {
    console.log(err);

    return [];
  }
};

export const formatRounds = (group: TournamentMatches) => {
  const rounds: Round[] = [];
  const matches = group?.tournament_matches;

  if (matches && matches?.Quarterfinals) {
    for (let i = 0; i < matches.Quarterfinals?.length; i++) {
      rounds.push(matches.Quarterfinals[i]);
    }
  }

  if (matches && matches?.Semifinals) {
    for (let i = 0; i < matches.Semifinals?.length; i++) {
      rounds.push(matches.Semifinals[i]);
    }
  }

  if (matches && matches?.Finals) {
    for (let i = 0; i < matches.Finals?.length; i++) {
      rounds.push(matches.Finals[i]);
    }
  }

  return rounds;
};
