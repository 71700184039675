import { t } from 'i18next';
import toast, { ValueOrFunction, Renderable } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';

const useToast = () => {
  const customizer = useSelector((state: AppState) => state.customizer);

  const toastPromise = ({
    request,
    onSuccess,
    onError,
    silent = false,
  }: {
    request: any;
    onSuccess: ValueOrFunction<Renderable, any>;
    onError: ValueOrFunction<Renderable, any>;
    silent?: boolean;
  }) => {
    if (silent) {
      // Handle the request silently
      request()
        .then((result) => {
          if (typeof onSuccess === 'function') {
            onSuccess(result);
          }
        })
        .catch((error) => {
          if (typeof onError === 'function') {
            onError(error);
          }
        });
    } else {
      // Handle the request with toast notifications
      toast.promise(
        request(),
        {
          loading: t('Common.loading'),
          success: onSuccess,
          error: onError,
        },
        {
          position: customizer?.toastPoistion || 'top-right',
        },
      );
    }
  };

  return {
    toastPromise,
  };
};

export default useToast;
